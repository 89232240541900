<template>
    <section>
        <div class="xeo-section2-container-fluid moz-padding-bottom xeo-modern-background-image">
            <Header2/>
            <div class="xeo-container xeo-container-mob-padding">
                <div class="xeo-mobileapp-dev">
                    <div class="xeo-mobileapp-heading">
                        <p>Mobile App Development</p>
                    </div>

                    <div class="xeo-mobileapp-details">

                        <div class="xeo-modern-web-paragraph">
                            <p>The process of developing software that can work on the mobiles is known as mobile app
                                development. Nowadays, mobile apps are creating a lot of buzz in the market and due to
                                its outstanding advantages and varied features, almost
                                all companies and organizations hailing from different industries are hiring people to
                                develop mobile applications for their company. These apps can be used for different
                                platforms like Android, Windows, iOS and much more
                                and can serve different purposes. These apps can be available as both server-side and
                                client-side processing software. But, creating state-of-the-art mobile applications
                                demands in-depth knowledge of technologies and coding
                                languages, and also extensive research and development. Thankfully, due to our expert
                                team of professionals, we can help our clients to own top quality mobile apps for their
                                use. </p>
                            <p>Thus when you are looking for your company’s mobile application that can facilitate your
                                clients then you can count on us. The process of developing mobile applications is very
                                challenging. Thus, trusting only experienced developers
                                like us is the best way for you to fulfill your objective. With the increasing number of
                                mobile apps in the market, it becomes a regular practice for the developing companies to
                                create monotonous mobile apps which are unable
                                to add any value to the owners and the users alike and are mostly unreliable in nature
                                as well. Thus, you must look for developers who are obsessed with creating top quality
                                and robust apps that can help you to reach your
                                customers easily and also lure more people. We are one of the few names in this industry
                                that has carved a niche ourselves in the industry and have a strong background in
                                creating award winning apps for the clients. So
                                choosing us can be the best bet for you to give a suitable answer to your peers and beat
                                the cutthroat competition in the market. </p>
                        </div>
                        <div class="xeo-modern-rwd xeo-modern-rwd-nopadding">
                            <!--<p>Mobile app development of Xeoscript </p>-->
                        </div>
                        <p>To give our clients a better way to reach to their customers and make the mobile apps the
                            best form of doing business, we cater different types of mobile application
                            development. </p>
                        <div class="xeo-mobileapp-ios-android clearfix">
                            <div class="xeo-mobileapp-ios">
                                <p>Android Apps</p>
                                <img src="../assets/images/android.png">
                                <!-- mobile images -->
                                <img src="../assets/images/mobile-images/ios.png">
                                <!-- mobile images -->
                                <p>There is no denying that Android is the most used platform in smartphones all over
                                    the world and thus the number of its users reach to a whopping number - almost 93
                                    million all over the world. Therefore, when you want to
                                    tap more market share for yourself, then choosing the Android apps is the best way
                                    for you. Come to us for the best solution and tailor-made apps for your use. </p>
                            </div>
                            <div class="xeo-mobileapp-android">
                                <p>iOS Apps</p>
                                <img src="../assets/images/ios.png">
                                <!-- mobile images -->
                                <img src="../assets/images/mobile-images/android.png">
                                <!-- mobile images -->
                                <p>While Android enjoys a major share of the Smartphone world, Apple iOS is not also
                                    very far behind. Thus creating apps for the iOS users also can give you an upper
                                    hand in the market and a better way to promote your brand
                                    and products to the mass. We have an expert team of professionals dedicated to
                                    developing iOS apps for our clients. </p>
                            </div>
                        </div>
                        <div class="xeo-mobileapp-ios-android clearfix">
                            <div class="xeo-mobileapp-ios">
                                <p>Web Services</p>
                                <img src="../assets/images/web-services.jpg">
                                <!-- mobile images -->
                                <img src="../assets/images/mobile-images/ios.png">
                                <!-- mobile images -->
                                <p>For catering better service, you must have a company by your side that can deliver
                                    top quality web services. With our Webservices, you can easily connect with your
                                    clients through the internet. </p>
                            </div>
                            <div class="xeo-mobileapp-android">
                                <p>Analysis</p>
                                <img src="../assets/images/analysis.jpg">
                                <!-- mobile images -->
                                <img src="../assets/images/mobile-images/android.png">
                                <!-- mobile images -->
                                <p>Not only creating apps, we also have the expertise and a name in the market for
                                    providing different kinds of analysis like behavioral analysis of mobile apps, which
                                    is very important for you to monitor performance. </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>
</template>

<script>
import Header2 from '../components/Header-2';

export default {
    name: 'Mobile-App-Development',
    components: { Header2 }
};
</script>

<style scoped>

</style>
