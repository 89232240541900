<template>
    <section>
        <div class="xeo-section2-container-fluid moz-padding-bottom xeo-modern-background-image">
            <Header2/>
            <div class="xeo-container xeo-container-mob-padding">
                <div class="xeo-bigdata-dev">
                    <div class="xeo-bigdata-heading">
                        <p>Big Data Analysis</p>
                        <p>Big Data Analytics is known as the process of examining large and varied data sets that range
                            from big data, uncover hidden patterns, market trends, customer preferences, unknown
                            correlations and other useful information that helps
                            several organizations to take better, informed decisions for their businesses. As one of the
                            reputed companies in the field of Big Data Analysis, Xeoscript makes use of various useful
                            tools that help the team to bring out the
                            best solutions for the clients. </p>
                    </div>
                    <div class="xeo-bigdata-details">
                        <div class="xeo-bigdata-application">
                            <!--<a href="javascript:void(0)"> Application</a>-->
                        </div>
                        <div class="xeo-bigdata-list clearfix">
                            <div class="xeo-bigdata-outer-list">
                                <div class="xeo-bigdata-spark-image xeo-bigdata-spark-bg">

                                </div>
                                <p><span>Apache Spark:</span> Open source parallel processing tool that helps the users
                                    to run and work with large scale data analytical applications across the clustered
                                    systems.</p>
                            </div>
                            <div class="xeo-bigdata-outer-list">
                                <div class="xeo-bigdata-pig-image">

                                </div>
                                <p><span>Pig:</span> Another popular open source tool that provides high-level mechanism
                                    for various parallel programming of MapReduce job types that are executed through
                                    Hadoop clusters.</p>
                            </div>
                            <div class="xeo-bigdata-outer-list">
                                <div class="xeo-bigdata-hive-image">

                                </div>
                                <p><span>Hive:</span> It is another open-source data warehouse system that is used for
                                    querying and finding large datasets that are stored in the Hadoop files.</p>
                            </div>
                            <div class="xeo-bigdata-outer-list display-none-casssandra">
                                <div class="xeo-bigdata-cassandra-image">
                                </div>
                                <p><span>Cassandra:</span> It is one of the popularly used NoSQL databases. It helps in
                                    enhancing the scalability and performance of database sets.
                                </p>
                            </div>

                        </div>
                        <div class="xeo-bigdata-list clearfix">
                            <div
                                class="xeo-bigdata-outer-list xeo-bigdata-hadoop-margin-left display-none-cassandra-two">
                                <div class="xeo-bigdata-cassandra-image">
                                </div>
                                <p><span>Cassandra:</span> It is one of the popularly used NoSQL databases. It helps in
                                    enhancing the scalability and performance of database sets.
                                </p>
                            </div>
                            <div class="xeo-bigdata-outer-list xeo-bigdata-hadoop-margin-left">
                                <div class="xeo-bigdata-hadoop-image">
                                </div>
                                <p><span>Hadoop:</span> Hadoop is an open-source programming framework that supports the
                                    processing and storage of extremely large data sets in a distributed computing
                                    environment.The Hadoop Distributed File System (HDFS)
                                    is designed to store very large data sets reliably.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import Header2 from '../components/Header-2';

export default {
    name: 'Bigdata',
    components: { Header2 }
};
</script>

<style scoped>

</style>
