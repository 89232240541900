<template>
    <section>
        <div class="xeo-section2-container-fluid moz-padding-bottom xeo-modern-background-image">

            <Header2/>

            <div class="xeo-container xeo-container-mob-padding">
                <div class="xeo-whoweare-dev">
                    <div class="xeo-whoweare-heading">
                        <p>About Us </p>
                    </div>
                    <div class="xeo-whoweare-headin whowe-title-style whowe-style-padding">
                        <p>Who We Are </p>
                    </div>
                    <div class="xeo-whoweare-details">
                        <div class="xeo-whoweare-paragraph">
                            <p>
                                We are some of the talented brains of web
                                development industry. Moreover, our team comprises of seasoned professionals who know
                                how to use technology for creating quality solutions for our clients, and a bunch of
                                fresh
                                and dedicated talents who can think out of the box and in an innovative way. Whether it
                                is web development or mobile apps, we are one of the best around. With the able guidance
                                of a visionary and our founder, we are in
                                a position to offer our clients extraordinary solutions that our peers fail to.
                            </p>

                        </div>
                    </div>
                    <div class="xeo-whoweare-headin whowe-title-style">
                        <p>What we do </p>
                    </div>
                    <div class="xeo-whoweare-details">
                        <div class="xeo-whoweare-paragraph">
                            <p>
                                <!-- <span>Xeoscript Technologies</span> -->Developing fresh and creative things is our
                                forte and web development is one of the services for which we have gained a lot of
                                praise. Add to that mobile app development, business analytics, we offer all kinds of
                                services
                                to our clientele that can help them to achieve excellence in their operational and
                                financial goals. Moreover, right from the planning stages to creating designs to
                                developing and testing, we cater all kinds of services
                                for websites and mobile apps. So you can expect several commercial solutions from us
                                like responsive web design to mobile apps, Facebook apps, e-commerce Big Data analysis
                                solutions that can easily help you to not only
                                go online but also to beat your competition and help your business effortlessly.
                            </p>

                        </div>
                    </div>
                    <div class="xeo-whoweare-headin whowe-title-style">
                        <p>How we started </p>
                    </div>
                    <div class="xeo-whoweare-details">
                        <div class="xeo-whoweare-paragraph">
                            <p>
                                We started our journey in the year 2014 as a
                                humble beginner with an aim to help business owners looking for perfect tailor-made
                                solutions for their business requirements. In the early days, we saw that business is
                                not
                                only confined to a brick and mortar store, rather a parallel and virtual market growing
                                on the World Wide Web. But unfortunately, most of the business owners were unaware of
                                this and thus losing a good chunk of business
                                clientele. In order to help them to go live online, we started our journey so that they
                                can easily find a friend and guide in us and grow their business with flying colors.
                            </p>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>
</template>

<script>
import Header2 from '../components/Header-2';

export default {
    components: { Header2 },
    metaInfo: {
        title: 'About Page'
    }
};
</script>
