<template>
    <section>
        <div class="xeo-section2-container-fluid">
            <Header2/>
            <div class="xeo-head-container-fluid xeo-work-bottom-margin">
                <div class="xeo-work-main">
                    <h1>Our Clients</h1>
                </div>
                <div class="xeo-work-para">
                    <p>We offer a multitude of services to our customers, specializing in design and development of
                        stylish, modern websites, web services, cloud data services and mobile apps.</p>
                </div>
            </div>
            <div class="xeo-head-container-fluid xeo-work-bottom-nomargin">
                <div class="xeo-work-gallery-main clearfix">
<!--                    <div class="xeo-work-gallery-links">-->
<!--                        <ul>-->
<!--                            <li class="active"><a href="javascript:void(0);" data-filter="*">SHOW ALL</a></li>-->
<!--                            <li><a href="javascript:void(0);" data-filter=".web">WEB</a></li>-->
<!--                            <li><a href="javascript:void(0);" data-filter=".adv">MOBILE APPS</a></li>-->
<!--                        </ul>-->
<!--                    </div>-->

                    <div class="xeo-work-gallery-images">
                        <div class="xeo-work-image web">
                            <img src="../assets/images/xeo-works/01-works.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>Fed-e-pos - Web App</p>
                                <p>UI/UX and development.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>Fed-e-pos - Web App</p>
                                <p>UI/UX and development.</p>
                            </div>
                        </div>
                        <div class="xeo-work-image adv">
                            <img src="../assets/images/xeo-works/02-works.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>Quizee</p>
                                <p>An online quiz app.</p><br>
                                <a class="quize-default-action">Coming Soon</a>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>Quizee</p>
                                <p>An online quiz app.</p>
                                <a class="quize-default-action">Coming Soon</a>
                            </div>
                        </div>
                        <div class="xeo-work-image brand">
                            <img src="../assets/images/xeo-works/03-al-shop.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>Alshop</p>
                                <p>Custom magento extensions.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>Alshop</p>
                                <p>Custom magento extensions.</p>
                            </div>
                        </div>
                        <div class="xeo-work-image web">
                            <img src="../assets/images/xeo-works/04-federal bank - epay portal.jpg"
                                 alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>Federal Bank Epay</p>
                                <p>UI/UX and development.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>Federal Bank Epay</p>
                                <p> UI/UX and development.</p>
                            </div>
                        </div>
                        <div class="xeo-work-image web">
                            <img src="../assets/images/xeo-works/05-korbion.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>Korbion</p>
                                <p> UI/UX and development.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>Korbion</p>
                                <p>UI/UX and development.</p>
                            </div>
                        </div>
                        <div class="xeo-work-image web">
                            <img src="../assets/images/xeo-works/06-fednet.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>Fednet</p>
                                <p> UI and UX for the Internet Banking.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>Fednet</p>
                                <p> UI and UX for the Internet Banking.</p>
                            </div>
                        </div>
                        <div class="xeo-work-image  web">
                            <img src="../assets/images/xeo-works/josalukas.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p> Jos Alukkas</p>
                                <p>Blue dart integration, cloud solution and optimisation.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>Jos Alukkas</p>
                                <p>Blue dart integration, cloud solution and optimisation.</p>
                            </div>
                        </div>
                        <div class="xeo-work-image  web">
                            <img src="../assets/images/xeo-works/curegarden.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>Cure Garden</p>
                                <p>Frontend and magento theme for the site.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>Cure Garden</p>
                                <p>Frontend and magento theme for the site.</p>
                                <a href="fednet.html">View More</a>
                            </div>
                        </div>
                        <div class="xeo-work-image  web">
                            <img src="../assets/images/xeo-works/orange.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>Orange Motif</p>
                                <p>UI/UX and development.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>Orange Motif</p>
                                <p>UI/UX and development.</p>
                            </div>
                        </div>
                        <div class="xeo-work-image  web">
                            <img src="../assets/images/xeo-works/newdragon.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>New Dragon</p>
                                <p>UI/UX and development.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>New Dragon</p>
                                <p>UI/UX and development.</p>
                            </div>
                        </div>
                        <div class="xeo-work-image  web">
                            <img src="../assets/images/xeo-works/sunandhini.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>Sunandhini</p>
                                <p>UI/UX and development.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>Sunandhini</p>
                                <p>UI/UX and development.</p>
                            </div>
                        </div>
                        <div class="xeo-work-image  web">
                            <img src="../assets/images/xeo-works/viva.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>Vvafuror</p>
                                <p>UI/UX and development.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>Vvafuror</p>
                                <p>UI/UX and development.</p>
                            </div>
                        </div>
                        <div class="xeo-work-image  web">
                            <img src="../assets/images/xeo-works/mep.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>MEP</p>
                                <p>UI/UX and development.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>MEP</p>
                                <p>UI/UX and development.</p>
                            </div>
                        </div>
                        <div class="xeo-work-image  web">
                            <img src="../assets/images/xeo-works/barber.jpg" alt="xeo-work">
                            <div class="xeo-work-image-link">
                                <p>Vineesh Rajan</p>
                                <p>UI/UX and development.</p><br>
                            </div>
                            <div class="xeo-work-link-text">
                                <p>Vineesh Rajan</p>
                                <p>UI/UX and development.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import Header2 from '../components/Header-2';

export default {
    name: 'Work',
    components: { Header2 }
};
</script>

<style scoped>

</style>
