<template>
    <div>
        <section id="home">
            <div class="xeo-main">
                <div class="xeo-main-container-fluid">

                    <Header/>

                    <div class="xeo-caption-overlay">
                        <p>" Unique people | Creative thinking "</p>
                        <p>Building Next Generation Cloud And Mobile Applications.</p>
                        <a href="#" v-scroll-to="'#xeo-offset'">View More</a>
                    </div>
                </div>
            </div>
        </section>
        <section>

            <div class="xeo-section2-container-fluid" id="xeo-offset">
                <div class="xeo-container xeo-container-mob-padding clearfix">
                    <div class="xeo-left-wwa-background-image">
                    </div>
                    <div class="xeo-right-wwa-text">
                        <p>Who <span class="xeo-text-span">we are</span></p>
                        <p>
                            We at Xeoscript are passionate about data. We understand how data is important for you to
                            achieve success in business. This begets the journey of our company. We are here to create
                            products that serve as a better way to transform disparate information
                            into data that will help you to analyze and understand about your company, work, customers
                            and
                            so many other things easily. We are here to help people and understand the data and create
                            better ways with which they can use data
                            to get success in their work. Put together a visionary, a few savvy business leaders and
                            professionals having a passion for data is the core strength of our company. We don’t just
                            focus
                            on making software or making complex spread
                            sheets for our clients, rather we are imbued to create products that will help them decipher
                            the
                            information that lie untouched before.
                        </p>
                        <div class="xeo-read-more">
                            <router-link to="/about/">Read more &nbsp;></router-link>
                        </div>

                    </div>
                </div>
                <div class="xeo-what-we-do padding-what-we-do">
                    <p>What we do</p>
                    <ul>
                        <li class="do-active">
                            <a href="#" v-scroll-to="'#big-data'"><img src="../assets/images/business.png"
                                                                       alt=""></a><span>BigData</span>
                        </li>
                        <li>
                            <a href="#" v-scroll-to="'#business-intel'"><img src="../assets/images/bigdata.png"
                                                                             alt=""></a><span>Business</span>
                        </li>
                        <li>
                            <a href="#" v-scroll-to="'#modern'"><img src="../assets/images/business.png"
                                                                     alt=""></a><span>Web</span></li>
                        <li>
                            <a href="#" v-scroll-to="'#mobile'"><img src="../assets/images/bigdata.png"
                                                                     alt=""></a><span>Mobile</span></li>
                        <!--<li><img src="" alt=""></li>-->
                    </ul>
                </div>
            </div>

            <div class="xeo-section2-container-fluid xeo-bg" id='big-data'>
                <div class="xeo-container xeo-container-mob-padding clearfix">
                    <div class="xeo-right-bda-background-image">

                    </div>
                    <div class="xeo-left-bda-text">
                        <p>Big<span class="xeo-text-span">Data Analysis</span></p>
                        <p>
                            Churning massive amounts of data and fetching the perfect information form is not an easy
                            task.
                            This is why the need of big data is felt more these days. Big data has matured in different
                            ways
                            than any other technologies. To analyze large chunks of data
                            one must need specialized software and different applications of data mining, predictive
                            analysis, data optimizer and forecasting. It sure is a tedious task altogether. But not with
                            us,
                            with our seasoned team of experts you can
                            easily get your data analyzed and in a form that can be easily understandable everyone in
                            your
                            company.
                        </p>
                        <div class="xeo-read-more">
                            <router-link to="/bigdata/">Read more &nbsp;></router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="xeo-section2-container-fluid " id="business-intel">
                <div class="xeo-container xeo-container-mob-padding clearfix">
                    <div class="xeo-left-bit-background-image">
                    </div>
                    <div class="xeo-right-bit-text">
                        <p>Business <span class="xeo-text-span">Intelligence </span></p>
                        <p>
                            We have created business intelligence tools that are aimed to give entrepreneurs and
                            decision-makers a better way to fetch the information they are always in search of. We
                            create
                            our business analytics tools with an aim of giving the management of every
                            company a better way to produce company results and quality reports so that taking decisions
                            will be way easier than before. Using robust and state-of-the-art technologies we tend to
                            empower our products in such way that can give
                            you all kinds of insights about your company and place you in a position form where taking
                            various decisions can be easy.
                        </p>
                        <div class="xeo-read-more">
                            <router-link to="/business-intelligence-tools/">Read more &nbsp;></router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="xeo-section2-container-fluid xeo-bg " id="modern">
                <div class="xeo-container xeo-container-mob-padding clearfix">
                    <div class="xeo-right-mwd-background-image">

                    </div>
                    <div class="xeo-left-mwd-text">
                        <p>Modern <span class="xeo-text-span">Web Development</span></p>
                        <p>In this age of internet and e-commerce, the virtual world is the best showcase for
                            corporations
                            and websites are their main secret to success. Thus, creating websites in such a way that
                            can
                            help you to achieve all your goals like drawing
                            more traffic, transforming potential customers into real ones, safe and secure browsing
                            experience for your customers is where our expertise lies. We understand the requirements of
                            our
                            clients more than anyone else and thus, creating
                            websites in tandem with their requirement is our forte.

                        </p>
                        <div class="xeo-read-more">
                           <router-link to="/modern-web-development/">Read more &nbsp;></router-link>
                        </div>
                    </div>

                </div>
            </div>

            <div class="xeo-section2-container-fluid xeo-mob-app-bgbottom" id="mobile">
                <div class="xeo-container xeo-container-mob-padding clearfix">
                    <div class="xeo-left-mad-background-image">
                    </div>
                    <div class="xeo-right-mad-text">
                        <p>Mobile <span class="xeo-text-span">App Development</span></p>
                        <p>
                            With the introduction of the Smartphone and smart devices, the world is now in our hands. We
                            tend to perform all kinds of activities from buying staples to paying utility bills and
                            teaching
                            children with the help of our smart devices. Thus, the need for
                            creating top quality mobile apps is growing leaps and bounds in the market. To offer our
                            clients
                            a better edge than their peers and creating a strong presence in the market, we develop high
                            quality mobile apps that can give them
                            a better grip in the market and a way to connect with the customers easily.
                        </p>
                        <div class="xeo-read-more">
                           <router-link to="/mobile-app-development/">Read more &nbsp;></router-link>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
// @ is an alias to /src

import Header from '../components/Header-1';

export default {
    metaInfo: {
        title: 'Home Page'
    },
    name: 'Home',
    components: { Header }
};

</script>
