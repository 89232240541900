<template>
    <section>
        <div class="xeo-section2-container-fluid moz-padding-bottom xeo-modern-background-image">

            <Header2/>

            <div class="xeo-container xeo-container-mob-padding">
                <div class="xeo-contact-dev">
                    <div class="xeo-contact-heading">
                        <p>Contact Us</p>
                    </div>
                    <div class="xeo-contact-details">
                        <div class="xeo-contact-form-map clearfix">
                            <div class="xeo-contact-form" id="parent">
                                <p>We'd love to hear from you! <br> <span>Brief us your requirement below.</span></p>
                                <transition name="fade" mode="out-in">

                                    <div class="alert alert-success mb-0 w-100 text-center my-5" v-if="state === 'S'"
                                         key="'S">
                                        <p class="my-5">The mail has been successfully submitted.</p>
                                    </div>

                                    <div class="alert alert-danger mb-0 w-100 text-center my-5"
                                         v-else-if="state === 'F'" key="'F">
                                        <p class="my-5">The mail delivery has been failed.</p>
                                    </div>

                                    <div class="alert alert-info mb-0 w-100 text-center my-5" v-else-if="state === 'L'"
                                         key="'L">
                                        <p class="my-5">Please wait while communicate with the server</p>
                                    </div>

                                    <form class="reservation-form" v-else @submit.prevent="onSubmit">
                                        <input type="text" v-model="name" id="name" placeholder="Name" name="name"
                                               required/>
                                        <input type="email" v-model="email" id="email" placeholder="Email" name="email"
                                               required/>
                                        <input type="text" v-model="subject" id="subject" placeholder="Subject"
                                               name="subject" required/>
                                        <textarea id="message" v-model="message" placeholder="comments" name="message"
                                                  required></textarea>
                                        <button type="submit" name="submit">Send</button>
                                    </form>
                                </transition>
                            </div>
                            <div class="xeo-contact-map">
                                <div id="map" style="display: none"
                                     class="embed-responsive embed-responsive-16by9"></div>
                                <div class="mapouter">
                                    <div class="gmap_canvas">
                                        <iframe width="100%" height="100%" id="gmap_canvas"
                                                src="https://maps.google.com/maps?q=xeoscript%20technologies%20mannuthy&t=&z=11&ie=UTF8&iwloc=&output=embed"
                                                frameborder="0" scrolling="no" marginheight="0"
                                                marginwidth="0"></iframe>
                                        <a href="https://putlocker-is.org"></a><br>
                                    </div>
                                </div>
<!--                                <a href="https://www.google.co.in/maps/place/Xeoscript+Technologies/@10.5333332,76.2552662,14z/data=!4m5!3m4!1s0x0:0x863b98ff3045461e!8m2!3d10.532848!4d76.262476"-->
<!--                                   target="blank">-->
<!--                                    <div id="map-canvas" style="height:200px"></div>-->
<!--                                </a>-->
                                <label>Addres</label>
                                <p>
                                    First Floor, Kalarikkal Building, Mannuthy ByPass, Mannuthy P O, Thrissur, PIN: 680
                                    651
                                </p>
                                <label>Phone</label>
                                <p>+91 9037692526<br> +91 9961989989
                                </p>
                                <label>Email</label>
                                <p>info@xeoscript.com<br> www.xeoscript.com
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Header2 from '../components/Header-2';

export default {
    name: 'Contact',
    components: { Header2 },
    data () {
        return {
            state: 'D',
            name: '',
            email: '',
            message: '',
            subject: ''
        };
    },

    methods: {
        onSubmit () {
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('email', this.email);
            bodyFormData.append('message', this.message);
            bodyFormData.append('consent', this.subject);

            const that = this;

            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://xeo.xeoscript.com/mail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },
        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        }
    },
    mounted () {
        const externalScript = document.createElement('script');
        externalScript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCLcHUv_0BgUju3PzVfJdq6BmjXoMH2Gi8&callback=myMap');
        document.head.appendChild(externalScript);
    }
};
</script>

<style scoped>
</style>
