<template>
    <section>
        <div class="xeo-section2-container-fluid moz-padding-bottom xeo-modern-background-image">
            <Header2/>
            <div class="xeo-container xeo-container-mob-padding">
                <div class="xeo-modern-web-dev">
                    <div class="xeo-modern-heading">
                        <p>Modern Web Development</p>
                    </div>
                    <div class="xeo-modern-web-details">
                        <div class="xeo-modern-web-paragraph">
                            <p>When it comes to modern web development, things are very complex and challenging. Gone
                                are the days when companies only use a simple static website and expect to get results.
                                Now, with the development of technology and ever
                                changing needs, websites have become the best tool with which a business or organization
                                can win the virtual battle for success on the World Wide Web. Moreover, websites are now
                                getting used as not only the best form of
                                creating awareness or informing customers about various updates, but also corporations
                                are poised to make their website a better way to sell their goods and services. </p>
                            <p>Thus, when you are looking for a website for your company, creating a static website is
                                not going to give you good results. This is the reason you should insist on building a
                                dynamic and responsive website that can help you
                                to sell your products online and can also help gain goodwill for your brand too. So when
                                you are looking to create a professional, responsive and technologically advanced
                                website, look no further. We at Xeoscript strive
                                to design and develop high quality, result-oriented websites that serve your
                                requirements, using the latest technologies and following the latest web design trends
                                and standards.</p>
                            <div class="xeo-modern-rwd xeo-modern-rwd-nopadding">
                                <p>Different types of websites that we offer. </p>
                            </div>
                            <p>In order to quench the demand of our clients, we have different kinds of websites which
                                are advanced and can be the best solution for your requirement. </p>
                        </div>
                        <div class="xeo-modern-rwd-awd clearfix">
                            <div class="xeo-modern-rwd">
                                <p>Responsive Web Design</p>
                                <img src="../assets/images/img-responsive.jpg">
                                <!-- mobile /static/images -->
                                <!--<img src="mobile-images/responsive.png">-->
                                <!-- mobile images -->
                                <p>This is one of the most popular website design methods which is revered by most
                                    people nowadays. Responsive design is the kind of web design that can respond to the
                                    environment or platform or screen size, orientation and
                                    user behavior. When you have a responsive website, it can be easily accessed from
                                    desktops and smartphones alike. We have a seasoned team of experts who can create
                                    exquisite responsive websites for our clients so that
                                    their aim of drawing more traffic and increase visibility can be fulfilled. </p>
                            </div>
                            <div class="xeo-modern-awd">
                                <p>Adaptive Web Design</p>
                                <img src="../assets/images/img-advncd.jpg">
                                <!-- mobile images -->
                                <!--<img src="mobile-images/advanced.png">-->
                                <!-- mobile images -->
                                <p>The name of this web design method is self-explanatory. This kind of design is very
                                    common these days for creating websites. With the help of this design, a website can
                                    change its layout and features based on the requirement
                                    and can offer perfect viewport for the reader. We can create amazing customized
                                    solutions for our customers with the help of latest adaptive web design. </p>
                            </div>
                        </div>
                        <div class="xeo-modern-rwd-awd clearfix">
                            <div class="xeo-modern-rwd">
                                <p>Web Application Development</p>
                                <img src="../assets/images/Modern-web-images/web_app_dev.jpg">
                                <!-- mobile images -->
                                <!--<img src="mobile-images/responsive.png">-->
                                <!-- mobile images -->
                                <p>Web applications now play an integral part in the success of a business website.
                                    Thus, having different kinds of web applications is necessary to offer better
                                    service to your customers. It is a kind of client-server software
                                    application that can be used for various purposes starting from webmail to online
                                    auctions, online sales and so on. With our outstanding R&D service, we can create
                                    top of the shelf web applications for our clients that
                                    can help to fulfill all their requirements.</p>
                            </div>
                            <div class="xeo-modern-awd">
                                <p>SEO</p>
                                <img src="../assets/images/Modern-web-images/content-writing.jpg">
                                <!-- mobile images -->
                                <!--<img src="mobile-images/advanced.png">-->
                                <!-- mobile images -->
                                <p>Almost all kinds of SEO strategies seem to be incomplete without the help of top
                                    quality content. Good content can give you a way to interact with your clients and
                                    visitors in a way that can impress them easily and transform
                                    them into real clients. We have a team of coveted writers who can create awesome
                                    contents that can be used to implement SEO strategies easily. </p>
                            </div>
                        </div>
                        <div class="xeo-modern-rwd-awd clearfix">
                            <div class="xeo-modern-rwd">
                                <p>UI / UX</p>
                                <img src="../assets/images/Modern-web-images/uiux.jpg">
                                <!-- mobile images -->
                                <!--<img src="mobile-images/responsive.png">-->
                                <!-- mobile images -->
                                <p>With top brass SEO companies like us, you can expect to get top quality UI / UX for
                                    your website. Without it, your website will be incomplete as these are the things
                                    which can make your clients’ browsing experience smoother
                                    and better. </p>
                            </div>
                        </div>
                        <div class="xeo-modern-expertise">
                            <p>Our Expertise</p>
                            <div class="xeo-modern-expertise-icon">

                                <div class="xeo-modern-icons-list clearfix">
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/html5.png">
                                        <p>HTML Websites</p>
                                    </div>
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/angularjs.png">
                                        <p>Angular Js</p>
                                    </div>
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/php.png">
                                        <p>PHP Development</p>
                                    </div>
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/wordpress.png">
                                        <p>WordPress Development</p>
                                    </div>
                                </div>

                                <div class="xeo-modern-icons-list clearfix">
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/jquery.png">
                                        <p>Jquery Websites</p>
                                    </div>
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/React.png">
                                        <p>React Js</p>
                                    </div>
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/django.png">
                                        <p>Django</p>
                                    </div>
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/js.png">
                                        <p>Javascript</p>
                                    </div>
                                </div>
                                <div class="xeo-modern-icons-list clearfix">
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/laravel.png">
                                        <p>Laravel</p>
                                    </div>
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/magento.png">
                                        <p>Magento Development</p>
                                    </div>
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/css.png">
                                        <p>CSS3 Websites</p>
                                    </div>
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/net.png">
                                        <p>ASP.NET</p>
                                    </div>
                                </div>

                                <div class="xeo-modern-icons-list clearfix">
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/pyt.png">
                                        <p>Python</p>
                                    </div>
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/mongo.png">
                                        <p>Mongodb</p>
                                    </div>
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/mysql.png">
                                        <p>MySQL</p>
                                    </div>
                                    <div class="xeo-modern-icons">
                                        <img src="../assets/images/oracle.png">
                                        <p>Oracle</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>
</template>

<script>
import Header2 from '../components/Header-2';

export default {
    name: 'Modern-Web-Development',
    components: { Header2 }
};
</script>

<style scoped>

</style>
