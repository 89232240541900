<template>
    <section>
        <div class="xeo-section2-container-fluid moz-padding-bottom xeo-modern-background-image">
            <Header2/>
            <div class="xeo-container xeo-container-mob-padding">
                <div class="xeo-Business-inteligence-dev">
                    <div class="xeo-Business-inteligence-heading">
                        <p> Business Intelligence Tools</p>
                        <p>Business intelligence tools refer to the applications or practices that are used to help
                            businesses to get a proper understanding of the commercial context while doing operations in
                            the correct manner. The main objective of a business
                            intelligence tool is to view, understand and analyse business information so that
                            organizations can start to make better-informed decisions that will benefit them in the
                            later years. Xeoscript uses the latest business intelligence
                            tools that help companies to make smart business decisions. </p>
                    </div>
                    <div class="xeo-Business-inteligence-details">
                        <div class="xeo-Business-inteligence-dataanalysis-datadashboard clearfix">
                            <div class="xeo-modern-rwd-awd clearfix">
                                <div class="xeo-Business-inteligence-dataanalysis">
                                    <p>Data Dashboards</p>
                                    <img src="../assets/images/Bussiness-images/data_dashboard.jpg">
                                    <!-- mobile images -->
                                    <img src="../assets/images/mobile-images/data-analysis.png">
                                    <!-- mobile images -->
                                    <p>With the help of digital dashboards, the managers of the companies can monitor
                                        the working of various departments in their organization. It provides a visual
                                        presentation of the performance measures.</p>
                                </div>
                                <div class="xeo-Business-inteligence-datadashboard">
                                    <p>Data Analysis</p>
                                    <img src="../assets/images/Bussiness-images/data_analysis.jpg">
                                    <!-- mobile images -->
                                    <img src="../assets/images/mobile-images/data-dashboard.png">
                                    <!-- mobile images -->
                                    <p>Data analysis is the process of inspecting, transforming, modeling and cleaning
                                        of the data with the objective to discover or gain useful information while
                                        taking the right decision for the company.</p>
                                </div>
                            </div>
                            <div class="xeo-modern-rwd-awd clearfix">
                                <div class="xeo-Business-inteligence-dataanalysis">
                                    <p>Website Analysis</p>
                                    <img src="../assets/images/Bussiness-images/website-analysis.jpg">
                                    <!-- mobile images -->
                                    <img src="../assets/images/mobile-images/data-analysis.png">
                                    <!-- mobile images -->
                                    <p>Web analytics is the measurement, analysis, collection and reporting of web data
                                        for various functions for the main aim to understand how to optimize the web
                                        page. </p>
                                </div>
                                <div class="xeo-Business-inteligence-datadashboard">
                                    <p>Mobile App Analysis</p>
                                    <img src="../assets/images/Bussiness-images/mobile_app_analysisi.jpg">
                                    <!-- mobile images -->
                                    <img src="../assets/images/mobile-images/data-dashboard.png">
                                    <!-- mobile images -->
                                    <p>With the mobile app analysis service from our company, we ensure that your apps
                                        are running and updated. It helps in crash monitoring, troubleshooting and
                                        back-end analysis.</p>
                                </div>
                            </div>
                            <div class="xeo-modern-rwd-awd clearfix">
                                <div class="xeo-Business-inteligence-dataanalysis">
                                    <p>Business Analysis Tools</p>
                                    <img src="../assets/images/data-analysis.png">
                                    <!-- mobile images -->
                                    <img src="../assets/images/mobile-images/data-analysis.png">
                                    <!-- mobile images -->
                                    <p>With the business analysis tools, businesses can reduce the need for the manual
                                        labour, improving accountability and also helps in improving communication.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>
</template>

<script>
import Header2 from '../components/Header-2';

export default {
    name: 'Business-intelligence',
    components: { Header2 }
};
</script>

<style scoped>

</style>
