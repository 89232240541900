<template>
    <header>
        <div class="xeo-head-container-fluid xeo-nav-static">
            <div class="xeo-container xeo-container-mob-padding">
                <div class="xeo-head-navigation clearfix">
                    <div class="xeo-logo xeo-scrollnav xeo-inner">
                        <router-link to="/"><img src="../assets/images/xeo-logo.png" width="180px"></router-link>
                    </div>
                    <div class="xeo-right-nav">
                        <div class="xeo-nav-list">
                            <ul>
                                <li>
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li>
                                    <router-link to="/work/">Work</router-link>
                                </li>
                                <li><a href="javascript:void(0);">Service</a>
                                    <ul>
                                        <li>
                                            <router-link to="/bigdata/">Big data analysis</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/business-intelligence-tools/">Business Intelligence
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/modern-web-development/">Modern web development
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/mobile-app-development/">Mobile app development
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <router-link to="/about/">About</router-link>
                                </li>
                                <li>
                                    <router-link to="/contact/">Contact</router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="xeo-social-icon xeo-social-scrollnav">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/xeoscript/" target="blank"><img
                                        src="../assets/images/soc-1.png" alt=""></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/xeoscript" target="blank"><img
                                        src="../assets/images/soc-2.png" alt=""></a>
                                </li>
                                <li>
                                    <a href="https://plus.google.com/109230747743230422424" target="blank"><img
                                        src="../assets/images/soc-3.png" alt=""></a>
                                </li>
                            </ul>
                        </div>
                        <!-- Aside nav bar -->
                        <div id="mySidenav" class="sidenav" :style="customStyle">
                            <ul>
                                <a href="javascript:void(0)" class="closebtn" @click="closeNav">&times;</a>
                                <li>
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li>
                                    <router-link to="/work/">Work</router-link>
                                </li>
                                <li><a href="javascript:void(0);">Service</a>
                                    <ul>
                                        <li>
                                            <router-link to="/bigdata/">Big data analysis</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/business-intelligence-tools/">Business Intelligence
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/modern-web-development/">Modern web development
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/mobile-app-development/">Mobile app development
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <router-link to="/about/">About</router-link>
                                </li>
                                <li>
                                    <router-link to="/contact/">Contact</router-link>
                                </li>
                            </ul>
                        </div>
                        <span @click="openNav" class="side-open"><img src="../assets/images/menu.png"
                                                                      width="50px"></span>

                    </div>
                    <!-- Aside nav bar -->
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'Header-2',
    data () {
        return {
            customStyle: 'width: 0'
        };
    },
    methods: {
        openNav () {
            this.customStyle = 'width: 250px;';
        },
        closeNav () {
            this.customStyle = 'width: 0';
        }
    }
};
</script>

<style scoped>

</style>
