import { render, staticRenderFns } from "./Big-Data-Analysis.vue?vue&type=template&id=18c4b4ff&scoped=true&"
import script from "./Big-Data-Analysis.vue?vue&type=script&lang=js&"
export * from "./Big-Data-Analysis.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c4b4ff",
  null
  
)

export default component.exports